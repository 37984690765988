export default {
  subtitle: 'The Taste of Nature and the Energy of Life in Every Bite',
  intro: {
    p1: 'We are a small family business dedicated to the production of vegan products in which pure and natural ingredients are a priority. We create our products from whole plant ingredients and avoid high temperatures in order to preserve the wealth of nutrients and enzymes that have a positive impact on human health and energy.',
    p2: 'As a family with three children, we know how important it is to eat healthy and wholesome food without compromising taste. This is why our mission is to create vegan products with the best composition, quality and taste. Every product that carries the Living Food brand has also been tested and approved at home by all three of our growing inspirations.',
    p3: 'Eating healthy can be a delightful experience. We are happy to share with you our products - prepared with lots of care and attention "from our family for yours". Thank you for being part of our story!',
  },
  feta: {
    name: 'Almond Brined Delicacy',
    subtitle: 'like feta cheese but made from almonds',
    desc: {
      p1: 'Almond delicacy in brine - this long and politically correct name is one of our most successful and loved products at Living Food, also known colloquially as Almond Brined "Feta".',
      p2: 'Its story began many years ago - back in 2013. The idea for it was born as an attempt to escape from the ubiquitous cashews, starches and soy in vegan dairy substitutes. We were looking for an alternative that would successfully replace the taste of the traditional white brined feta cheese and, if possible, surpass it in terms of health benefits. To be eaten on its own but also to use in traditional recipes such as cheese pies (banitsa) for example. Its ingredients should be healthy and of high-quality but also as few as possible. With a lot of trial and error, experiments and improvements, we created an almond "feta" that we can give to our children without remorse. In addition to its history, here is more of what makes our Almond Brined "Feta" so special:',
    },
    list: {
      i1: 'The first and only probiotic vegan white brined almond "cheese" on the market, one of a kind, made following the original author\'s recipe, by hand and with lots of love.',
      i2: 'The main ingredient is almonds which contain a very high number of healthy nutrients - they are rich in protein, dietary fiber, healthy fats and a number of organic vitamins, minerals and antioxidants.',
      i3: 'In the production process, we activate the nuts, which increases the content and digestibility of their useful ingredients.',
      i4: "It contains only 4 simple natural ingredients (for which you won't need a dictionary) and billions of live and beneficial probiotic organisms (Lactobacillus bulgaricus, Lactobacillus acidophilus, Streptococcus thermophilus, Lactobacillus rhamnosus, Lactobacillus lactis).",
      i5: 'We prepare it with our own plant-based starter, rich in beneficial probiotic bacteria and do not use laboratory probiotics from tablets, capsules and powders.',
      i6: 'Our almond "feta" goes through a long process of natural fermentation, which makes it irresistibly tasty and easily digestible.',
      i7: 'We have developed a production technology that is as gentle as possible to preserve and develop all the beneficial properties of the ingredients.',
      i8: 'Thanks to our unique production technology, our almond "feta" has a fine texture and a unique taste that develops and improves over time.',
      i9: 'No added fats.',
      i10: 'Does not contain GMOs, gluten, preservatives, starches, flavors, flavorings, enhancers, colorings, yeast, soy, lactose, casein, nor any other known or unknown "advancements" of the modern food industry.',
      i11: '100% plant-based - does not contain any products of animal origin.',
      i12: 'It is a perfect substitute for white brined cheese.',
      i13: 'With the option of changing the salinity of the cheese to suit every taste (by diluting the brine or adding extra salt to it) - just like with traditional dairy brined cheeses.',
      i14: 'It is suitable for consumption by vegans, by raw foodists, by people following a keto diet, during fasting, and of course by all who like and appreciate it.',
      i15: 'Low in calories - especially compared to traditional dairy cheeses.',
      i16: 'Created and produced in Bulgaria.',
    },
  },
  cashew: {
    name: 'Cultured Cashew',
    subtitle: 'like cream cheese but made from cashew',
    desc: {
      p1: 'For the creation of our fermented cashew, the main driver was the need for a basic product for some favorite traditional recipes such as "tarator", tzatziki, cream cheese sandwiches, soup bases, cheesecakes, etc. Its creation also began many years ago, but unlike our almond "feta" which is a conceptually new product on the market, the use of cashews in the production of vegan cheeses and desserts was and is widespread. Yet, having tried various cashew-based products, we could not find "our own". Similar products usually contain a large amount of extracted fats (e.g. coconut oil), salt, sometimes starches and/or flavorings... True to our beliefs that a minimal list of unadulterated ingredients is the secret to healthy and delicious food, we created this wonderful product.',
      p2: 'Our cultured cashew does not need any extra additives - it is tasty without them, and healthier. It is suitable both for direct consumption and for use in a number of recipes. What sets our Cultured Cashew apart from other similar products on the market:',
      p3: 'Our Cultured Cashew is a versatile product, with countless uses and limitless possibilities for culinary creations:',
    },
    list1: {
      i1: 'Cashews, being the main ingredient, allow the development of a rich flavor and texture that approaches that of many dairy products.',
      i2: 'In the production process, we activate the nuts, which increases the content and digestibility of their useful ingredients.',
      i3: "It contains only 3 simple natural ingredients (you won't need a dictionary) and billions of live and beneficial probiotic organisms (Lactobacillus bulgaricus, Lactobacillus acidophilus, Streptococcus thermophilus, Lactobacillus rhamnosus, Lactobacillus lactis).",
      i4: 'We prepare it with our own plant-based starter rich in healthy probiotic bacteria and do not use laboratory probiotics from tablets, capsules and powders.',
      i5: 'Our cultured cashew goes through a long process of natural fermentation making it irresistibly delicious and easily digestible.',
      i6: 'We have developed a production technology that is as gentle as possible to preserve and develop all the beneficial properties of the ingredients.',
      i7: 'It has a fine texture and a unique flavor that develops and improves over time.',
      i8: 'No added fat.',
      i9: 'Does not contain GMOs, gluten, preservatives, starches, flavors, flavorings, enhancers, colorings, yeast, soy, lactose, casein, nor any other known or unknown "advancements" of the modern food industry.',
      i10: '100% plant-based - does not contain any products of animal origin.',
      i11: 'With a balanced salt content - also suitable for people who prefer products with less or no salt.',
      i12: 'It is suitable for consumption by vegans, by raw foodists, by people observing a keto diet, during fasting and, of course, by all who like and appreciate it.',
      i13: 'Low in calories - especially compared to traditional dairy cheeses.',
      i14: 'Created and produced in Bulgaria.',
    },
    list2: {
      i1: 'For direct consumption - with cucumbers, etc. vegetables, spread on a slice, or whatever you want for instant pleasure.',
      i2: 'To replace sour and condensed milk - with the addition of a little bit of water (depending on the desired thickness), it can be consumed in combination with muesli and fruit, prepare "tarator", use as a dairy-free addition to soup and many others dishes.',
      i3: 'For creating sauces and dressings - with the addition of spices and/or different sauces, it can become a wonderful creamy accent or sauce in various dishes, or an irresistible salad dressing, etc.',
      i4: 'For desserts - it can be used to prepare various desserts - from delicate creams to tempting cheesecakes satisfying every sweet craving.',
    },
  },
  salami: {
    name: 'Tsveklanka and Piquantka',
    subtitle: 'like lukanka but made from beatroot',
    desc: {
      p1: 'Like all our products, vegan "lukanka" is the result of a long and careful process of perfecting the recipe and production technology. We managed to make them so appetizing that often even fans of classic meat products are pleasantly surprised by the rich taste of this plant-based version of the traditional dried sausage.',
    },
    list: {
      i1: 'A delicious vegan alternative to the popular appetizer in two versions - natural and pleasantly spicy (piquante).',
      i2: 'Their texture, taste and aroma are reminiscent of the classic "lukanka", but without the heavy and unpleasant smell of meat, as well as without the violence and loss of life associated with its production.',
      i3: 'Rich in vegetable proteins - the purest form of amino acids that the body needs, with a high content of useful fats, fibers, vitamins and minerals.',
      i4: 'They contain only beetroot, sun-dried tomatoes, seeds and spices - each of which is mentioned on the label.',
      i5: 'We grind the spices for our spice mix ourselves for maximum flavor and freshness. We do not use any ready-made nor any Asian spice mixes, unlike many others on the market do - it is important for us to know the composition and to be sure of the quality of every single ingredient that we put in our products.',
      i6: 'In the production process, we dry the lukanki slowly and continuously, at a low temperature not exceeding 42°C, in order to enhance the flavors and preserve the richness of nutrients and enzymes of the input products.',
      i7: 'We do not add any fats and we do not squeeze out the juice of the tomatoes and beets - as with all our products, we preserve the maximum nutritional content of each ingredient.',
      i8: 'They do not contain GMOs, gluten, preservatives, starches, flavors, flavorings, enhancers, colorings, yeast, soy, lactose, casein nor any other known or unknown "advancements" of the modern food industry.',
      i9: '100% plant-based - they do not contain any animal products.',
      i10: 'A perfect alternative to the popular appetizer.',
      i11: 'It is suitable for consumption by vegans, by raw foodists, by people following a keto diet, during fasting, and of course by all who like and appreciate it.',
      i12: 'Created and produced in Bulgaria.',
    },
  },
}

import { Container } from "semantic-ui-react"

const storesMapUrl = 'https://www.google.com/maps/d/embed?mid=1fE8v2tZq1BexB2oR_HrAoe6N6iWDyks&ehbc=2E312F'

const Stores = () => {
    return (
        <Container>
            <iframe
                title='Магазини'
                src={storesMapUrl}
                width="100%"
                height="500"
            >
                Loading...
            </iframe>
        </Container>
    )
}

export default Stores
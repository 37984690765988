import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en'
import bg from './locales/bg'

const resources = {
  en,
  bg,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: ['en', 'bg'],
    fallbackLng: 'bg',

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ['querystring', 'localStorage']
    }
  })

export default i18n

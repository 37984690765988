import _ from 'lodash'
import { Segment, Header, List, Image } from "semantic-ui-react"
import { useTranslation } from 'react-i18next';

const Feta = () => {
    const { t } = useTranslation('common')
    return (
        <Segment basic padded>
            <Header
                textAlign='center'
                as='h2'
                content={t('products.salami.name')}
                subheader={t('products.salami.subtitle')} />

            <Segment basic clearing >
                <Image src='/p1-1.jpg' size='large' floated='left' />

                <p>{t('products.salami.desc.p1')}</p>
            </Segment>

            <List relaxed bulleted>
                {_.map(_.range(12), (i) => (
                    <List.Item key={i}>{t(`products.salami.list.i${i + 1}`)}</List.Item>
                ))}
            </List>
        </Segment>
    )
}

export default Feta

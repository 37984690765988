import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Menu, Button, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import i18n from '../../i18n'
import './Header.scss'

class Header extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  toggleLanguage() {
    i18n.changeLanguage(i18n.language === 'en' ? 'bg' : 'en')
  }

  render() {
    const { t } = this.props

    return (
      <Container className='main-header'>
        <Link to='/' className='logo'>
          <Image alt={t('name')} src='/logo.png' />
        </Link>

        <Menu text size='tiny'>
          <Menu.Menu>
            <Menu.Item>
              <Button
                circular
                icon='facebook'
                color='facebook'
                href='//facebook.com/livingfood.bg'
                target='_blank'
              />
            </Menu.Item>
            <Menu.Item>
              <Button
                circular
                icon='instagram'
                color='instagram'
                href='//instagram.com/livingfoodbg'
                target='_blank'
              />
            </Menu.Item>
            <Menu.Item>
              <Button
                circular
                icon='twitter'
                color='twitter'
                href='//twitter.com/livingfoodbg'
                target='_blank'
              />
            </Menu.Item>

            <Menu.Item onClick={this.toggleLanguage}>
              {_.upperCase(i18n.language === 'en' ? 'български' : 'English')}
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Container>
    )
  }
}

export default withTranslation(['common'])(Header)

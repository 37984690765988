import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Header, Grid, Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import TopMenu from './TopMenu'
import './HomeView.scss'
import { Link } from 'react-router-dom'

class HomeView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props

    return (
      <div className='home-view'>
        <div className='center-image'>
          <Container>
            <TopMenu size='large' inverted fluid borderless />
          </Container>
        </div>

        <Grid container padded='vertically' doubling>
          <Grid.Row>
            {/* <Grid.Column textAlign='center' >
              <Button
                style={{ opacity: 0.9 }}
                as={Link}
                to='/easter-request?ref=home'
                color='red'
                size='large'
                content='Заявка за Великден'
                icon='arrow right'
                labelPosition='right'
              />
              <Button
                style={{ opacity: 0.9 }}
                as={Link}
                to='/sweet-christmas?ref=home'
                color='red'
                size='large'
                content='Заявка за коледни сладки'
                icon='arrow right'
                labelPosition='right'
              />
            </Grid.Column> */}
            <Grid.Column textAlign='center'>
              <Button
                as={Link}
                to='/request?ref=home'
                positive
                size='large'
                content='Заявка за веган "сирене" и "луканка"'
                icon='arrow right'
                labelPosition='right'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid container padded='vertically' doubling columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Header>{t('home.when')}</Header>
              <p>{t('stories.when')}</p>
            </Grid.Column>

            <Grid.Column>
              <Header>{t('home.where')}</Header>
              <p>{t('stories.where')}</p>
              {/* <p className='text-small'>
                <Trans i18nKey='home.address' components={[<br />]} />
              </p>
              <Button
                basic
                size='small'
                icon='map outline'
                content={t('home.see-map')}
                href='https://www.google.bg/maps/place/ул.+„Иван+Вазов“+28,+Варна'
                target='_blank'
              /> */}
            </Grid.Column>

            <Grid.Column>
              <Header>{t('home.what')}</Header>
              <p>{t('stories.what')}</p>
              {/* <Header sub color='red' size='large' content={t('coming-soon')} /> */}
            </Grid.Column>

            <Grid.Column>
              <Header>{t('home.why')}</Header>
              <p>{t('stories.why')}</p>
              {/* <Header sub color='red' size='large' content={t('coming-soon')} /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default withTranslation(['common'])(HomeView)

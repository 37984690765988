import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu, Sidebar as SemanticSidebar, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { toggleSidebar } from '../../store/sidebar'
import './Sidebar.scss'

class Sidebar extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { visible, toggle, t } = this.props

    return (
      <SemanticSidebar
        as={Menu}
        visible={visible}
        inverted
        vertical
        width='thin'
        animation='overlay'
        direction='right'
        onClick={toggle}
      >
        <Menu.Item as={NavLink} to='/' header activeClassName='active'>
          <Icon name='home' />
          {t('name')}
        </Menu.Item>
        {_.map(['products', 'stores', 'contact'], (key) => (
          <Menu.Item
            key={key}
            as={NavLink}
            activeClassName='active'
            to={`/${key}`}>
            {t(`menu.${key}`)}
          </Menu.Item>
        ))}
      </SemanticSidebar>
    )
  }
}

export default connect(
  (state) => ({ visible: state.sidebar.visible }),
  (dispatch) => bindActionCreators({ toggle: toggleSidebar }, dispatch)
)(withTranslation(['common'])(Sidebar))

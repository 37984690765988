import products from './products'

export default {
  common: {
    name: 'Жива Храна',
    'coming-soon': 'Очаквайте',
    menu: {
      about: 'За нас',
      stores: 'Магазини',
      products: 'Продукти',
      contact: 'Контакт',
      gallery: 'Галерия',
    },
    header: {
      subtitle: 'Сурова Веган Кухня',
    },
    home: {
      when: 'Кога',
      where: 'Къде',
      what: 'Какво',
      why: 'Защо',
      address: 'ул. „Иван Вазов“ 28<0 />Варна',
      'see-map': 'Карта',
      message:
        'Скъпи приятели, затворени сме поради Lockdown #3. Работим само с предварителни поръчки.',
    },
    days: {
      mon: 'понеделник',
      tue: 'вторник',
      wed: 'сряда',
      thu: 'четвъртък',
      fri: 'петък',
      sat: 'събота',
      sun: 'неделя',
    },
    hours: {
      a: '11:30 - 18:00 ч.',
      b: '11:30 - 16:00 ч.',
    },
    market: 'Фермерски Пазар',
    closed: 'затворено',
    'working-hours': 'Работно време',
    contact: {
      phone: '089 295 3495',
      location:
        'Ще ни откриете на тиха уличка в сърцето на града. На минутки от Севастопол, Катедралата и Общината.',
      contactUs: 'Свържете се с нас',
      'social-links': 'Последвайте ни и в социалните мрежи:',
      'message-us': 'Пишете ни',
      name: 'Име',
      email: 'Имейл',
      message: 'Съобщение',
      placeholder: 'Въпроси, коментари, предложения...',
      send: 'Изпрати',
      feedbackSentHeader: 'Благодарим ви!',
      feedbackSentMessage: 'Съобщението ви беше изпратено успешно.',
    },
    stories: {
      when: 'Преди много години, в зората на семейството ни, стремежът към здраве и вегетарианството се превърнаха в нашия начин на живот. С появата на първото ни дете преминахме към веган хранене с превес към суровоядство. След години на създаване и изпробване на рецепти за трите ни растящи вдъхновения, днес предлагаме и на вас вкусни и здравословни веган продукти.',
      where:
        'Нашият малък семеен бизнес се намира на тиха уличка в сърцето на Варна. Там създавахме и споделяхме с вас нашите веган изкушения. Днес, нашето заведение е затворено и ни липсва да ви посрещаме с усмивка, за да ви предложим най-добрите веган продукти. Но се развиваме и вече можете да намерите част от тях в търговската мрежа.',
      what: 'Вярваме, че здравословното хранене не трябва да бъде скучно и безвкусно. Сега предлагаме веган сирена и луканки, които сме създали за нашето семейство и искаме да споделим с вас. Надяваме се скоро отново да можем да ви предложим и любимите на всички опитали ги веган торти и десерти.',
      why: 'Като семейство с три деца, знаем колко е важно да се храним здравословно, но и да е вкусно. Нашите рецепти са създадени с грижа и любов, а продуктите ни са с чист и натурален състав, за да предоставим на вас същото качество и вкус, които искаме и за себе си.',
    },
    products
  },
}

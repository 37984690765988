export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR })

const initialState = {
  visible: false,
}

export default function sidebarReducer(state = initialState, action) {
  return action.type === TOGGLE_SIDEBAR
    ? {
        visible: !state.visible,
      }
    : state
}

import products from './products'

export default {
  common: {
    name: 'Living Food',
    'coming-soon': 'Coming soon',
    menu: {
      about: 'About',
      products: 'Products',
      stores: 'Stores',
      contact: 'Contact',
      gallery: 'Gallery',
    },
    header: {
      subtitle: 'raw vegan cuisine',
    },
    home: {
      when: 'When',
      where: 'Where',
      what: 'What',
      why: 'Why',
      address: '28 "Ivan Vazov" St<0 />Varna, Bulgaria',
      'see-map': 'See map',
      message:
        "Dear friends, we're currently closed due to Lockdown #3. We only fulfill advance orders for pick-up by appointment.",
    },
    days: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun',
    },
    hours: {
      a: '11:30am - 6:00pm',
      b: '11:30am - 4:00pm',
    },
    market: "Farmer's Market",
    closed: 'closed',
    'working-hours': 'Hours',
    contact: {
      location: "You'll find us on a quiet street in the heart of the city.",
      contactUs: 'Contact us',
      'social-links': 'Follow us on social media:',
      'message-us': 'Leave us a message',
      name: 'Name',
      email: 'Email',
      message: 'Message',
      placeholder: 'Questions, comments, suggestions...',
      send: 'Send',
      feedbackSentHeader: 'Thank you!',
      feedbackSentMessage: 'Your message has been succesfully sent.',
    },
    stories: {
      when: "Many years ago, at the beginning of our family's journey, our focus on health and vegetarianism became our way of life. With the arrival of our first child, we transitioned to a vegan diet with an emphasis on raw foods. After years of creating and experimenting with recipes for our three growing inspirations, we now offer delicious and healthy vegan products for you to enjoy.",
      where:
        'Our small family business is located on a quiet street in the heart of Varna. There, we created and shared our vegan delights with you. Though our establishment is currently closed, we look forward to welcoming you back with a smile and offering you the best vegan products. In the meantime, you can find some of our products in the market.',
      what: "We believe that healthy eating should never be boring or tasteless. That's why we now offer vegan cheese and sausages that we have created for our family and want to share with you. We hope to bring back soon the beloved vegan cakes and desserts that everyone who has tried them adores.",
      why: 'As a family with three children, we understand the importance of healthy and tasty eating. Our recipes are crafted with care and love, and our products are made with natural and pure ingredients, ensuring that you enjoy the same quality and flavor that we want for ourselves.',
    },
    products
  },
}

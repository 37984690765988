import React from 'react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

const RequestForm = ({ title, src }) => {
  return (
    <>
      <Helmet title={`Жива Храна | ${title}`} />
      <iframe
        title={title}
        src={src}
        width='100%'
        height='100%'
        frameBorder='0'
      >
        Loading…
      </iframe>
    </>
  )
}

export default withTranslation(['common'])(RequestForm)

export default {
  subtitle: 'Вкусът на Природата и Енергията на Живота във Всяка Хапка',
  intro: {
    p1: 'Ние сме малък семеен бизнес посветен на производството на веган продукти, в които чистия и натурален състав е приоритет. Създаваме нашите продукти от пълноценни растителни съставки и избягвайки високи температури - за да запазим богатството им от хранителни вещества и ензими, които имат положително въздействие върху здравето и енергията на човека.',
    p2: 'Като семейство с три деца, знаем колко е важно да се храним пълноценно и здравословно, без това да води до компромиси с вкуса. Затова мисията ни е да създаваме веган продукти с най-добрия състав, качество и вкус. Всеки продукт, който носи марката “Жива Храна”, е тестван и одобрен у дома и от трите ни растящи вдъхновения.',
    p3: 'Здравословното хранене може да бъде истинска наслада. Щастливи сме, да споделим с вас нашите продукти - приготвени с много грижа и внимание „от нашето семейство за вашето“. Благодарим ви, че сте част от нашата история!',
  },
  feta: {
    name: 'Бадемико БулгариВкус',
    subtitle: 'Деликатес от бадеми в саламура - като Бяло сирене, но от Бадеми',
    desc: {
      p1: 'Деликатес от бадеми в саламура - това дълго и политически коректно наименование е на един от най-успешните ни и любими продукти в Жива Храна, накратко и разговорно познато още като Бадемовото саламурено “сирене”.',
      p2: 'Неговата история започна преди много години - в далечната 2013 г. Идеята за него се роди като опит за бягство от вечните и масови кашу, нишестета и соя във веган заместителите на млечни продукти. Търсихме алтернатива, която успешно да замества вкусово традиционното бяло саламурено сирене и по възможност да го надмине по полезност. Да може да се хапва директно, но и да се използва в традиционни рецепти като млечна баница например. Да е с изчистен състав, с качествени и полезни, но и минимум като брой съставки. С много опити и грешки, експерименти и подобрения, създадохме “сирене” от бадеми, което без угризения даваме на децата си. Освен историята му, ето и още от това, което прави нашето Бадемово “сирене” толкова специално:',
    },
    list: {
      i1: 'Първото и единствено по рода си пробиотично веган бяло саламурено “сирене” от бадеми на пазара, уникално по рода си, създадено по оригинална авторска рецепта, ръчно и с много любов.',
      i2: 'Основната съставка са бадемите, които съдържат изключително много полезни хранителни вещества - те са богати на белтъчини (протеини), диетични фибри, здравословни мазнини и редица органични витамини, минерали и антиоксиданти.',
      i3: 'В процеса на производство активираме ядките, което увеличава съдържанието и усвояемостта на полезните им съставки.',
      i4: 'Съдържа само 4 прости натурални съставки (за които не ви е нужен речник) и милиарди живи и полезни пробиотични организми (Lactobacillus bulgaricus, Lactobacillus acidophilus, Streptococcus thermophilus, Lactobacillus rhamnosus, Lactobacillus lactis).',
      i5: 'Приготвяме го с наша собствена растителна закваска, богата на полезни пробиотични бактерии и не използваме лабораторни пробиотици от таблетки, капсули и прахчета.',
      i6: 'Бадемовото ни “сирене” преминава през дълъг процес на естествена ферментация, което го прави неустоимо вкусно и лесно усвоимо.',
      i7: 'Разработихме технология на производство, която е максимално щадяща, за да се запазят и разгърнат всички полезни свойства на вложените съставки.',
      i8: 'Благодарение на нашата уникална технология на производство, бадемовото ни “сирене” притежава фина текстура и уникален вкус, който се развива и подобрява с течение на времето.',
      i9: 'Без добавени мазнини.',
      i10: 'Не съдържа ГМО, глутен, консерванти, нишестета, аромати, овкусители, подобрители, оцветители, мая, соя, лактоза, казеин и др. знайни и незнайни постижения на съвременната хранителна индустрия.',
      i11: '100% растително - не съдържа никакви продукти от животински произход.',
      i12: 'Перфектен заместител е на бялото краве саламурено сирене.',
      i13: 'С възможност за промяна на солеността на сиренето за да угоди на всеки вкус (чрез разреждане на саламурата или добавяне на допълнително сол в нея) - също както при традиционните млечни саламурени сирена.',
      i14: 'Подходящо е за консумация от вегани, от суровоядци, от хора спазващи кето режим, по време на пости, както разбира се и от всички които го харесват и ценят.',
      i15: 'Нискокалорично - особено в сравнение с традиционните млечни сирена.',
      i16: 'Създадено и произведено в България.',
    },
  },
  cashew: {
    name: 'Заквашу',
    subtitle: 'Заквасено кашу - като Крема сирене, но от Кашу',
    desc: {
      p1: 'За създаването на заквасеното ни кашу основен виновник беше нуждата от основен продукт за някои любими традиционни рецепти като например таратор, снежанка, сандвич с крема сирене, застройка на супи, чийзкейкове и т.н. Неговото сътворяване също започна преди много години, но за разлика от бадемовото ни сирене, което е концептуално нов продукт за пазара, използването на кашу при производството на веган сирена и десерти беше и е широкоразпространено. И все пак, консумирайки различни видове и варианти, не успявахме да открием “нашето”. Обичайно подобни продукти са с добавка на голямо количество екстрахирани мазнини (напр. кокосово масло), сол, понякога нишестета и/или овкусители… Верни на на нашите убежденията, че изчистеният състав е пътят към здравословната и вкусна храна, създадохме този прекрасен продукт.',
      p2: 'Нашето заквасено кашу не се нуждае от излишни добавки - то е вкусно и без тях, а и по-полезно. Подходящо е както за директна консумация, така и за използване в редица рецепти. Какво отличава нашето Заквасено кашу от други подобни продукти на пазара:',
      p3: 'Заквасеното ни кашу е гъвкав продукт, с безброй приложения и безгранични възможности за кулинарни творения:',
    },
    list1: {
      i1: 'Кашуто, като основна съставка, позволява развитието на богат вкус и текстура, които се приближават до много от млечните продукти.',
      i2: 'В процеса на производство активираме ядките, което увеличава съдържанието и усвояемостта на полезните им съставки.',
      i3: 'Съдържа само 3 прости натурални съставки (за които не ви е нужен речник) и милиарди живи и полезни пробиотични организми (Lactobacillus bulgaricus, Lactobacillus acidophilus, Streptococcus thermophilus, Lactobacillus rhamnosus, Lactobacillus lactis).',
      i4: 'Приготвяме го с наша собствена растителна закваска, богата на полезни пробиотични бактерии и не използваме лабораторни пробиотици от таблетки, капсули и прахчета.',
      i5: 'Заквасеното ни кашу преминава през дълъг процес на естествена ферментация, което го прави неустоимо вкусно и лесно усвоимо.',
      i6: 'Разработихме технология на производство, която е максимално щадяща, за да се запазят и разгърнат всички полезни свойства на вложените съставки.',
      i7: 'Притежава фина текстура и уникален вкус, който се развива и подобрява с течение на времето.',
      i8: 'Без добавени мазнини.',
      i9: 'Не съдържа ГМО, глутен, консерванти, нишестета, аромати, овкусители, подобрители, оцветители, мая, соя, лактоза, казеин и др. знайни и незнайни постижения на съвременната хранителна индустрия.',
      i10: '100% растително - не съдържа никакви продукти от животински произход.',
      i11: 'С балансирано съдържание на сол - подходящо и за хора, които предпочитат по-безсолни продукти.',
      i12: 'Подходящо е за консумация от вегани, от суровоядци, от хора спазващи кето режим, по време на пости, както разбира се и от всички които го харесват и ценят.',
      i13: 'Нискокалорично - особено в сравнение с традиционните млечни сирена.',
      i14: 'Създадено и произведено в България.',
    },
    list2: {
      i1: 'За директна консумация - с краставички и др. зеленчуци, намазано на филийка, или каквото пожелаете за моментално удоволствие;',
      i2: 'За заместване на кисело и цедено мляко - с добавяне на малко вода (в зависимост от търсената гъстота), може да се консумира в комбинация с мюсли и плодове, да се приготви таратор или снежанка, да се използва като безмлечна застройка на супа и много други ястия.',
      i3: 'За създаване на сосове и дресинги - с добавяне на подправки и/или различни сосове може да се превърне в прекрасен кремообразен акцент или сос в различни ястия, или пък в неустоим дресинг за салата и т.н.',
      i4: 'За десерти - може да се използва за приготвяне на различни десерти - от нежни кремове, до изкусителни чийзкейкове задоволяващи всяко сладко желание.',
    },
  },
  salami: {
    name: 'Цвекланка и Пикантка',
    subtitle: 'като Луканка, но от Цвекло',
    desc: {
      p1: 'Като всички наши продукти, веган “луканките” са резултат от дълъг и грижлив процес на усъвършенстване на рецептата и технологията за производство. Успяхме да ги направим толкова апетитни, че често дори почитателите на класическите продукти от месо биват приятно изненадани от богатия вкус на тази растителна версия на традиционната луканка.',
    },
    list: {
      i1: 'Вкусна веган алтернатива на популярното мезе в два варианта - натурален и приятно пикантен.',
      i2: 'Текстурата, вкусът и ароматът им напомнят на класическата луканка, но без тежката и неприятна за много хора миризма на месо, както и без насилието и отнетия живот свързани с производството му.',
      i3: 'Богати на растителни белтъчини (протеини) - най-чистата форма на аминокиселините, от които тялото се нуждае, с високо съдържание на полезни мазнини, фибри, витамини и минерали.',
      i4: 'Съдържат само червено цвекло, сушени домати, семена и подправки - всяка една, от които е упомената на етикета.',
      i5: 'Приготвяме ги без да използваме готови и/или азиатски миксове от подправки, за разлика от многото други на пазара - за нас е важно да познаваме състава и да сме сигурни в качеството на всяка една съставка, която влагаме в продуктите си и затова сами смиламе (за максимален вкус и свежест) подправките за нашия микс.',
      i6: 'В процеса на производство, сушим “луканките” бавно и продължително, на ниски температура ненадвишаващи 42°C, за да се развият вкусовете и да се запази богатството от хранителни вещества и ензими на вложените продукти.',
      i7: 'Не добавяме мазнини и не изцеждаме сока на доматите и цвеклото - както при всеки наш продукт запазваме максимално хранителното съдържание на всяка съставка.',
      i8: 'Не съдържат ГМО, глутен, консерванти, нишестета, аромати, овкусители, подобрители, оцветители, мая, соя, лактоза, казеин и др. знайни и незнайни постижения на съвременната хранителна индустрия.',
      i9: '100% растителни - не съдържат никакви продукти от животински произход.',
      i10: 'Перфектна алтернатива на популярното мезе.',
      i11: 'Подходящи са за консумация от вегани, от суровоядци, от хора спазващи кето режим, по време на пости, както разбира се и от всички които ги харесват и ценят.',
      i12: 'Създадено и произведено в България.',
    },
  },
}

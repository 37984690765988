import _ from 'lodash'
import { Segment, Header, List, Image } from "semantic-ui-react"
import { useTranslation } from 'react-i18next';

const Cashew = () => {
    const { t } = useTranslation('common')
    return (
        <Segment basic padded>
            <Header
                textAlign='center'
                as='h2'
                content={t('products.cashew.name')}
                subheader={t('products.cashew.subtitle')} />
            <Segment basic clearing >
                <Image src='/p3-1.jpg' size='large' floated='left' />

                <p>{t('products.cashew.desc.p1')}</p>
                <p>{t('products.cashew.desc.p2')}</p>
            </Segment>

            <List relaxed bulleted>
                {_.map(_.range(14), (i) => (
                    <List.Item key={i}>{t(`products.cashew.list1.i${i + 1}`)}</List.Item>
                ))}
            </List>

            <p>{t('products.cashew.desc.p3')}</p>

            <List relaxed bulleted>
                {_.map(_.range(4), (i) => (
                    <List.Item key={i}>{t(`products.cashew.list2.i${i + 1}`)}</List.Item>
                ))}
            </List>
        </Segment>
    )
}

export default Cashew

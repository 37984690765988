import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Form, Message } from 'semantic-ui-react'

const submitUrl =
  'https://script.google.com/macros/s/AKfycbz3KdVuk7tjnVYk-VOc5hk234dncAGMU5bG6VwYukWft4Dhb_BL/exec'

class FeedbackForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  state = {
    name: '',
    email: '',
    message: '',
    loading: false,
    submitted: false,
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleFeedbackSumit = () => {
    const data = new FormData()
    ;['name', 'email', 'message'].forEach((field) => {
      data.append(field, this.state[field])
    })

    this.setState({ loading: true })
    fetch(submitUrl, {
      mode: 'no-cors',
      method: 'POST',
      body: data,
    })
      .then(() => this.setState({ submitted: true, loading: false }))
      .catch(() => {
        this.setState({ loading: false })
        // TODO log somewhere, ga perhaps
      })
  }

  render() {
    const { t } = this.props
    const { name, email, message, loading, submitted } = this.state

    if (submitted) {
      return (
        <Message
          success
          header={t('contact.feedbackSentHeader')}
          content={t('contact.feedbackSentMessage')}
        />
      )
    }

    return (
      <Form onSubmit={this.handleFeedbackSumit} loading={loading}>
        <Form.Input
          name='name'
          value={name}
          onChange={this.handleChange}
          label={t('contact.name')}
        />

        <Form.Input
          name='email'
          value={email}
          onChange={this.handleChange}
          label={t('contact.email')}
          type='email'
        />

        <Form.TextArea
          name='message'
          value={message}
          onChange={this.handleChange}
          required
          label={t('contact.message')}
          placeholder={t('contact.placeholder')}
          rows={8}
        />

        <Form.Button>{t('contact.send')}</Form.Button>
      </Form>
    )
  }
}

export default withTranslation(['common'])(FeedbackForm)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu, Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { toggleSidebar } from '../../store/sidebar'
import './TopMenu.scss'

class TopMenu extends Component {
  static propTypes = {
    toggleSidebar: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { toggleSidebar, t } = this.props

    return (
      <Menu
        className='top-menu'
        {..._.omit(this.props, 'toggleSidebar', 'tReady', 't')}
      >
        <Menu.Menu position='right'>
          <Menu.Item
            as={NavLink}
            exact
            to='/'
            activeClassName='active'
            icon
            fitted='vertically'
          >
            <Icon name='home' size='large' />
          </Menu.Item>
          {_.map(['products', 'stores', 'contact'], (key) => (
            <Menu.Item
              key={key}
              as={NavLink}
              activeClassName='active'
              to={`/${key}`}
            >
              {t(`menu.${key}`)}
            </Menu.Item>
          ))}
          <Menu.Item
            id='sidebar-toggle'
            icon='sidebar'
            onClick={toggleSidebar}
          />
        </Menu.Menu>
      </Menu>
    )
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ toggleSidebar }, dispatch)
)(withTranslation(['common'])(TopMenu))

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import App from './components/App'
import { configureStore } from './store/createStore'
import './i18n'
import GA from './components/GoogleAnalytics'

import 'semantic-ui-less/semantic.less'
import './styles/core.scss'

const store = configureStore()
const root = createRoot(document.getElementById('root'))

const render = (Component) => {
  return root.render(
    <Provider store={store}>
      <BrowserRouter>
        {GA.init() && <GA.RouteTracker />}
        <Component />
      </BrowserRouter>
    </Provider>
  )
}

render(App)

import _ from "lodash"
import { Switch, Link, Route, Redirect } from "react-router-dom"
import { Container, Divider, Header, Menu } from "semantic-ui-react"
import { useTranslation } from 'react-i18next';

import Feta from './Feta'
import Cashew from './Cashew'
import Cveklanka from './Cveklanka'

const Products = ({ match: { params: { name } } }) => {
    const { t } = useTranslation('common')
    return (
        <Container>
            <Header
                textAlign='center'
                as='h2'
                content={t('name')}
                subheader={t('products.subtitle')}
            />

            <p>{t('products.intro.p1')}</p>
            <p>{t('products.intro.p2')}</p>
            <p>{t('products.intro.p3')}</p>

            <Menu size='huge' pointing secondary color='green' widths={3}>
                {_.map([
                    [t('products.feta.name'), 'деликтес-от-бадеми-в-саламура'],
                    [t('products.cashew.name'), 'заквасено-кашу'],
                    [t('products.salami.name'), 'цвекланка-пикантка'],
                ], ([title, to]) => (
                    <Menu.Item
                        key={to}
                        active={name === to}
                        name={title}
                        as={Link}
                        to={`/products/${to}`}
                    />
                ))}
            </Menu>
            <Route index render={() => <Redirect to='/products/деликтес-от-бадеми-в-саламура' />} />
            <Switch>
                <Route path='/products/деликтес-от-бадеми-в-саламура' component={Feta} />
                <Route path='/products/цвекланка-пикантка' component={Cveklanka} />
                <Route path='/products/заквасено-кашу' component={Cashew} />
            </Switch>

            <Divider hidden />
            <Divider hidden />
        </Container>
    )
}

export default Products

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Container, Grid, Divider, List, Icon } from 'semantic-ui-react'

import FeedbackForm from './FeedbackForm'
import './ContactView.scss'

class ContactView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  /* eslint-disable max-len */
  static mapUrl =
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2908.203236550504!2d27.9149432!3d43.2052264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a453f5eeebdda7%3A0xa19d84a8b7f312fb!2sul.+%22Ivan+Vazov%22+28%2C+9000+Varna!5e0!3m2!1sen!2sbg!4v1485618871993'
  /* eslint-enable max-len */

  render() {
    const { t } = this.props

    return (
      <Container className='contact-view'>
        {/* <iframe src={ContactView.mapUrl} allowFullScreen title='Map' /> */}

        <Grid columns={2} stackable relaxed>
          <Grid.Column>
            <Divider horizontal section>
              {t('contact.contactUs')}
            </Divider>
            {/* <p>{t('contact.location')}</p> */}
            <List relaxed>
              {/* <List.Item
                icon='marker'
                content={<Trans i18nKey='home.address' components={[', ']} />}
              /> */}
              <List.Item
                icon='phone'
                content={<a href='tel:+3590892953495'>{t('contact.phone')}</a>}
              />
              <List.Item
                icon='mail'
                content={
                  <a href='mailto:info@livingfood.bg'>info@livingfood.bg</a>
                }
              />
            </List>

            <p>{t('contact.social-links')}</p>
            <List>
              <List.Item>
                <a
                  href='//facebook.com/livingfood.bg'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='facebook' /> facebook.com/<b>livingfood.bg</b>
                </a>
              </List.Item>
              <List.Item>
                <a
                  href='//instagram.com/livingfoodbg'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='instagram' /> instagram.com/<b>livingfoodbg</b>
                </a>
              </List.Item>
              <List.Item>
                <a
                  href='//twitter.com/livingfoodbg'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='twitter' /> twitter.com/<b>livingfoodbg</b>
                </a>
              </List.Item>
            </List>

          </Grid.Column>

          <Grid.Column>
            <Divider horizontal section>
              {t('contact.message-us')}
            </Divider>
            <FeedbackForm />
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

export default withTranslation(['common'])(ContactView)

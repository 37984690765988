import React, { Component, Suspense } from 'react'
import { Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Loader } from 'semantic-ui-react'

import CoreLayout from './CoreLayout'

class App extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <HelmetProvider>
        <Suspense fallback={<Loader active size='large' />}>
          <Route component={CoreLayout} />
        </Suspense>
      </HelmetProvider>
    )
  }
}

export default App

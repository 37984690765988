import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'

import sidebarReducer from './sidebar'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    sidebar: sidebarReducer,
    ...asyncReducers,
  })
}

const initialState = {}

export const configureStore = () => {
  let store

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  )

  return store
}
